/*
 |-----------------------------------------------------------------------------
 | components/atoms/Checkbox/Checkbox.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';
@import 'styles/tokens/control';
@import 'styles/tokens/label';

.block {
	/* stylelint-disable-next-line selector-class-pattern */
	&.isDisabled {
		@include disabled();
	}
}

.control {
	display: none;

	&:checked {
		~ .indicator {
			&::after {
				display: block;
			}
		}
	}
}

.indicator {
	$size: div($tap-area, 3);

	@include flex('center', 'center');
	@include transition('border-color');
	background-color: $control-background-color;
	border: $control-border-width solid $control-border-color;
	border-radius: $curvature;
	height: $size;
	order: 1;
	width: $size;

	&::after {
		@include display('none', 4px, 10px);
		@include rotate(45deg);
		border: solid $control-color;
		border-width: 0 2px 2px 0;
		content: '';
	}

	&:hover {
		background-color: $control-hover-background-color;
	}

	&:focus {
		box-shadow: $focus;
	}
}

.label {
	color: $label-color;
	font-size: 1.3rem;
	margin-left: div($grid-gap, 2);
	order: 2;
}

.legend {
	color: $label-color;
	font-size: $label-font-size;
}

.option {
	--space: 8px;

	@include flex($column: 'center');
}

.primary {
	.indicator {
		background-color: $control-background-color;
		border-color: $control-border-color;

		&::after {
			border-color: $control-color;
		}

		&:hover {
			background-color: $control-hover-background-color;
		}
	}

	.label {
		color: $label-color;
	}

	.legend {
		color: $label-color;
	}
}

.secondary {
	.indicator {
		background-color: $control-background-color;
		border-color: $brand-1;

		&::after {
			border-color: $brand-1;
		}

		&:hover {
			background-color: $control-hover-background-color;
		}
	}

	.label {
		color: $brand-1;
	}

	.legend {
		color: $brand-1;
	}
}
